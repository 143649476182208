<template>
  <app-tutorial-button
    :tutorials="tutorials"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import TutorialExcursionFilters from '@extranet/components/tutorials/excursion/TutorialExcursionFilters.vue'

const { t } = useI18n()

// ---------- TUTORIALS ----------

const tutorials = computed(() => ([
  {
    menuItem: t('ttmt.tutorials.extranet.excursions.filters.main_title'),
    component: TutorialExcursionFilters,
  },
]))
</script>
