<template>
  <app-overlay
    :show="show"
    @clicked="handleClose"
  >
    <app-modal @closed="handleClose">
      <app-spinner
        class="text-theme-500 mb-2"
        size="2x"
      />

      <p>
        {{ t('ttmt.pdf.common.generating') }}
      </p>

      <p class="mb-4">
        {{ t('ttmt.pdf.common.waiting') }}
      </p>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'closed',
])

const { t } = useI18n()

function handleClose() {
  emits('closed')
}
</script>
