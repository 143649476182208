import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'

export default function breadcrumbExcursion() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('ttmt.breadcrumbs.excursions.excursions'),
        link: false,
        to: '/excursions',
      },
    ]
  }

  /**
   * Breadcrumb for show page
   *
   * @param excursion
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function show(excursion) {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: excursion.label ?? excursion.id,
        link: false,
        to: `/excursions/${excursion.id}`,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for create page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function create() {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.excursions.create'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for edit page
   *
   * @param excursion
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function edit(excursion) {
    let breadcrumbs = show(excursion)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.edit'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for share page
   *
   * @param excursion
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function share(excursion) {
    let breadcrumbs = show(excursion)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.share'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  return {
    index,
    show,
    create,
    edit,
    share,
  }
}
