<template>
  <app-separator
    v-if="separator"
    :text="t('ttmt.auth.messages.or')"
    class="my-4"
  />

  <div class="break-all p-4 border rounded-lg">
    <app-copy-clipboard
      :text="link"
      size="w-10"
      left-margin="ml-4"
      class="my-2"
    />
  </div>

  <p
    v-if="hintTitle"
    :class="['text-xs', 'text-center', 'mt-1', marginBottom]"
  >
    {{ hintTitle }}
  </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppSeparator from '@shared/components/ui/AppSeparator.vue'
import AppCopyClipboard from '@shared/components/ui/AppCopyClipboard.vue'

defineProps({
  // Display separator
  separator: {
    type: Boolean,
    default: true,
  },
  // Link URL
  link: {
    type: String,
    required: true,
  },
  // Hint title
  hintTitle: {
    type: String,
    default: null,
  },
  // Margin bottom
  marginBottom: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()
</script>
