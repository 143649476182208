<template>
  <div>
    <p class="font-bold">
      {{ t('ttmt.excursions.form.your_excursion') }}
    </p>

    <p class="text-sm mb-3">
      {{ t('ttmt.excursions.form.order_hint') }}
    </p>

    <div class="h-full">
      <!-- sortable list -->
      <form-group
        name="excursions_points_of_interest"
        :label="false"
        type="sortable-list"
        :submittable="false"
        class="h-full"
        wrapper-classes="flex h-full"
        :form-control-props="{
          classes: 'h-full',
          draggableGroup: 'excursions_points_of_interest'
        }"
      >
        <template #control-vue-draggable-item="{ element }">
          <point-of-interest-card
            :resource="getPointOfInterestResource(element)"
            :with-link="false"
            hide-actions
            class="p-4 cursor-pointer"
            @click="displayPointOfInterestModal(element.point_of_interest_id)"
          />
        </template>
      </form-group>

      <!-- hidden fields -->
      <template v-if="form.values.excursions_points_of_interest?.length > 0">
        <template
          v-for="(item, index) in form.values.excursions_points_of_interest"
          :key="index"
        >
          <form-group
            :name="`excursions_points_of_interest.${index}.id`"
            type="hidden"
          />
          <form-group
            :name="`excursions_points_of_interest.${index}.point_of_interest_id`"
            type="hidden"
          />
          <form-group
            :name="`excursions_points_of_interest.${index}.position`"
            type="hidden"
          />
          <form-group
            :name="`excursions_points_of_interest.${index}._destroy`"
            type="hidden"
          />
        </template>
      </template>

      <template v-else>
        <!-- keep an empty array value, avoid null/undefined value, to make it usable in drag and drop  -->
        <form-group
          :name="`excursions_points_of_interest`"
          :initial-value="[]"
          type="hidden"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useFormFields from '@shared/hooks/form/formFields'
import FormGroup from '@shared/components/form/FormGroup.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'

const props = defineProps({
  // json api resource used to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Hide or not the search form group
  hideSearch: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const store = useStore()

const { form } = useFormFields(props)

// get points of interest resources from the relationship
const relationshipPois = computed(() => (
  props
    .resource
    ?.relationships
    ?.excursions_points_of_interest
    ?.map((exc) => exc?.relationships?.point_of_interest) || []
))

function getPointOfInterestResource(item) {
  // Get the resource from the points of interest from store,
  // or the ones from the relationship
  const pois = relationshipPois.value
    .concat(store.state.resources.pointsOfInterest)

  return pois.find((poi) => (
    poi.id === item.point_of_interest_id
  ))
}

function displayPointOfInterestModal(id) {
  store.commit('modals/SET_PROPERTIES', {
    name: 'point_of_interest_show',
    display: true,
    id,
  })
}
</script>
