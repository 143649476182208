<template>
  <teleport-wrapper
    v-if="excursion && !store.state.spinner.active"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <excursion-form
    v-if="excursion && !store.state.spinner.active"
    :resource="excursion"
    :additional-errors="excursionFormErrors"
    :submitting="excursionFormSubmitting"
    :force-step="3"
    :force-mode="mode"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { compact } from 'lodash'

import {
  fetchExcursion as fetchApiExcursion,
  fetchPointsOfInterest,
} from '@shared/http/api'
import breadcrumbExcursion from '@extranet/breadcrumbs/excursion'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ExcursionForm from '@extranet/components/resources/excursion/main_form/ExcursionForm.vue'
import useLocale from '@shared/hooks/locale'
import useExcursionForm from '@shared/hooks/resources/form/excursion'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- EXCURSION ----------

const excursion = ref({})
const mode = route.query.mode

async function fetchExcursion() {
  const { id } = route.params

  await fetchApiExcursion(
    id,
    { 'mode': 'edit' },
  )
    .then((response) => {
      excursion.value = response.data.data
    })
}

// ---------- POI ----------

async function fetchPois() {
  const pointsOfInterestIds = []

  // Get POI ids from route query params
  if (route.query.point_of_interest_id) {
    pointsOfInterestIds.push(route.query.point_of_interest_id)
  }

  // If there is no poi related yet, get user's poi's id
  if (
    excursion.value.relationships.excursions_points_of_interest.length === 0
    && store.state.auth.user.relationships.point_of_interest?.id
  ) {
    pointsOfInterestIds.push(store.state.auth.user.relationships.point_of_interest.id)
  }

  if (pointsOfInterestIds.length > 0) {
    await fetchPointsOfInterest({
      'filter[id]': compact(pointsOfInterestIds),
      'mode': 'index',
    })
      .then((response) => {
        // Prefill relationships with poi resources
        const formattedRelations = response.data.data?.map((poi) => ({
          relationships: {
            point_of_interest: poi,
          },
        })) ?? []

        excursion.value.relationships.excursions_points_of_interest.push(
          ...formattedRelations,
        )
      })
  }
}

// ---------- FORM ----------

const {
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
  resource: excursion,
})

function onSubmitSuccess(response) {
  router.push({ name: 'show-excursions', params: { id: response.data.data?.id } })
  store.commit(
    'flashes/ADD_FLASH',
    { message: t('ttmt.excursions.update.success'), type: 'success' },
  )
}

// ---------- BREADCRUMBS ----------

const { edit } = breadcrumbExcursion()

const breadcrumbs = computed(() => (
  edit({
    id: excursion.value?.id,
    label: getAttributeTranslation(excursion.value?.attributes?.title),
  })
))

// ---------- LIFECYCLES ----------

onMounted(async () => {
  store.commit('spinner/ENABLE')

  await fetchExcursion()
  await fetchPois()

  store.commit('spinner/DISABLE')
})

onUnmounted(() => {
  store.commit('filters/RESET_FILTERS', 'pointsOfInterest')
})
</script>
