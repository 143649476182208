<template>
  <div>
    <div>
      <!-- Search -->
      <form-control-text
        name="points_of_interest_search"
        :icon-props="{ library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' }"
        :placeholder="t('ttmt.points_of_interest.index.search')"
        :enter-event-handler="(e) => e.preventDefault()"
        @changed="handleSearch"
      />

      <!-- Points of interest card list -->
      <template v-if="!loading">
        <template
          v-if="availablePointsOfInterestFormatted.length > 0"
        >
          <draggable
            :list="availablePointsOfInterestFormatted"
            :group="{ name: 'excursions_points_of_interest', put: false }"
            item-key="point_of_interest_id"
          >
            <template
              #item="{index}"
            >
              <app-card-item
                :key="index"
                class="my-4 p-3 flex items-center justify-between"
              >
                <point-of-interest-card
                  :resource="availablePointsOfInterest[index]"
                  :with-link="false"
                  hide-actions
                  class="flex-1-min-w-0 cursor-pointer"
                  @click="displayPointOfInterestModal(availablePointsOfInterest[index].id)"
                />

                <app-icon
                  library="fontawesome"
                  class="text-theme-500 cursor-pointer"
                  name="plus"
                  size="lg"
                  @click="handlePointOfInterestSelected(availablePointsOfInterest[index].id)"
                />
              </app-card-item>
            </template>
          </draggable>
        </template>

        <p
          v-else-if="searchedOnce"
          class="mt-8 text-center"
        >
          {{ t('ttmt.points_of_interest.index.no_results') }}
        </p>
      </template>

      <!-- Loading -->
      <template v-else>
        <point-of-interest-card-empty
          v-for="i in 5"
          :key="i"
          class="my-4"
        />
      </template>

      <!-- Next page -->
      <div class="mt-5">
        <template v-if="lastPage && page < lastPage">
          <a
            v-if="nextPageFetching === false"
            href="#"
            class="block text-center app-link w-full"
            @click.prevent="nextPage"
          >
            {{ t('ttmt.common.actions.load_more') }}
          </a>
        </template>

        <a
          v-if="nextPageAvailable && !nextPageFetching"
          href="#"
          class="block text-center app-link w-full"
          @click.prevent="nextPage"
        >
          {{ t('ttmt.common.actions.load_more') }}
        </a>

        <div
          v-if="nextPageFetching"
          class="text-center w-full"
        >
          <app-spinner
            class="text-theme-500"
            size="lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  inject,
  watch,
  toRefs,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { isNil } from 'lodash'
import draggable from 'vuedraggable'

import { fetchPointsOfInterest } from '@shared/http/api'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import FormControlText from '@shared/components/form/controls/FormControlText.vue'
import PointOfInterestCardEmpty from '@extranet/components/resources/point_of_interest/PointOfInterestCardEmpty.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'

const props = defineProps({
  // Index filters
  filters: {
    type: Object,
    default: () => ({}),
  },
  // Force refresh when this prop changes
  forceRefresh: {
    type: Number,
    required: true,
  },
})

const emits = defineEmits(['pointOfInterestSelected'])

const { t } = useI18n()
const store = useStore()

const form = inject('form', ref({}))
const nextPageFetching = ref(false)
const pointsOfInterest = ref([])
const page = ref(1)
const lastPage = ref(null)
const loading = ref(false)
const searchedOnce = ref(false)
const nextPageAvailable = ref(false)

function fetchPois(nextPage = false) {
  if (nextPage === false) {
    loading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'mode': 'index',
    'source': 'extranet',
    'filters': props.filters,
  }

  fetchPointsOfInterest(params)
    .then((response) => {
      // Make the resources available in the store
      store.commit(
        'resources/ADD_RESOURCES',
        {
          type: 'pointsOfInterest',
          resources: response.data.data,
        },
      )

      const oldPointsOfInterest = pointsOfInterest.value
      const newPointsOfInterest = response.data.data

      if (nextPage) {
        // Add new results after the old ones
        pointsOfInterest.value = [...oldPointsOfInterest, ...newPointsOfInterest]
      } else {
        // Replace the old results with the new ones
        pointsOfInterest.value = newPointsOfInterest
      }

      lastPage.value = response.data.meta?.last_page

      // Check if navigation available
      nextPageAvailable.value = !isNil(response.data.links?.next)

      if (!searchedOnce.value) {
        searchedOnce.value = true
      }
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

function resetPage() {
  page.value = 1
}

function nextPage() {
  page.value += 1
  fetchPois(true)
}

// Form selected points of interest's ids array
const selectedPointsOfInterestIds = computed(() => (
  form.value.values.excursions_points_of_interest
    ?.filter((el) => !el._destroy)
    ?.map((el) => el.point_of_interest_id)
  || []
))

// Points of interest which are not already selected
const availablePointsOfInterest = computed(() => (
  pointsOfInterest.value.filter((poi) => (
    !selectedPointsOfInterestIds.value.includes(poi.id)
  ))
))

// Points of interest index formatted for the form
const availablePointsOfInterestFormatted = computed(() => (
  availablePointsOfInterest.value
    .map((poi) => ({
      point_of_interest_id: poi.id,
      point_of_interest: poi,
    }))
))

function handlePointOfInterestSelected(id) {
  emits('pointOfInterestSelected', id)
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid triggering an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }

  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchPois()
  }, 400)
}

// ---------- FILTERS ----------

const { forceRefresh } = toRefs(props)

watch(forceRefresh, () => {
  resetPage()
  fetchPois()
})

// ---------- POI MODAL ----------

function displayPointOfInterestModal(id) {
  store.commit('modals/SET_PROPERTIES', {
    name: 'point_of_interest_show',
    display: true,
    id,
  })
}
</script>
