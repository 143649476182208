<template>
  <div>
    <form-translated
      :form-group-props="{
        name: 'title',
        formLabelProps: {
          required: true,
        },
      }"
    />

    <div class="flex justify-start">
      <form-group
        name="kind"
        type="radio"
        :form-control-props="{
          options: excursionKindOptions,
        }"
      >
        <template #help-button>
          <ul>
            <li class="mb-5">
              <p class="font-bold">
                {{ t('enums.App\\Enums\\Excursion\\KindEnum.customized') }}
              </p>

              {{ t('ttmt.form.hints.excursion.kind.pro.customized') }}
            </li>

            <li>
              <p class="font-bold">
                {{ t('enums.App\\Enums\\Excursion\\KindEnum.root') }}
              </p>

              {{ t('ttmt.form.hints.excursion.kind.pro.root') }}
            </li>
          </ul>
        </template>
      </form-group>

      <form-group
        :initial-value="resource.attributes?.favorite"
        name="favorite"
        :label="t('validation.attributes.favorite')"
        type="switch"
        class="mt-1 ml-6"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import useSelectOptions from '@shared/hooks/form/selectOptions'
import FormTranslated from '@shared/components/form/FormTranslated.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

const {
  excursionKindOptions,
} = useSelectOptions()
</script>
