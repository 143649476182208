<template>
  <teleport-wrapper
    v-if="excursion && !loading"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <excursion-show
    v-if="excursion && !loading"
    :resource="excursion"
    :loading="loading"
    :destroy-callback="handleDestroy"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchExcursion as fetchApiExcursion,
  destroyExcursion,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import breadcrumbExcursion from '@extranet/breadcrumbs/excursion'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ExcursionShow from '@extranet/components/resources/excursion/ExcursionShow.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useStore()
const { getAttributeTranslation } = useLocale()

// ---------- EXCURSION ----------

const excursion = ref(null)
const loading = ref(true)

function fetchExcursion() {
  loading.value = true
  const { id } = route.params
  const params = {
    'mode': 'show',
  }

  fetchApiExcursion(id, params)
    .then((response) => {
      excursion.value = response.data.data
    })
    .finally(() => {
      loading.value = false
    })
}

function handleDestroy() {
  destroyExcursion(excursion.value.id).then(() => {
    router.push({ name: 'index-excursions' })
    store.commit(
      'flashes/ADD_FLASH',
      { message: t('ttmt.excursions.destroy.success'), type: 'success' },
    )
  })
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbExcursion()

const breadcrumbs = computed(() => (
  show({
    id: excursion.value?.id,
    label: getAttributeTranslation(excursion.value?.attributes?.title),
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchExcursion()
})
</script>
