<template>
  <app-alert
    design="info"
    :title="t('ttmt.form.hints.translatable.pro_global_title')"
    class="mb-4"
  >
    <p>
      {{ t('ttmt.form.hints.translatable.pro_global') }}
    </p>

    <p>
      {{ t('ttmt.form.hints.translatable.pro_global_2') }}
    </p>
  </app-alert>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppAlert from '@shared/components/ui/AppAlert.vue'

const { t } = useI18n()
</script>
